.header {
  background-repeat: no-repeat;
  height: 650px; /* Default height for larger screens */
  max-width: 100%;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-around;
  text-align: center;
  position: relative; /* For positioning child elements */
}

@media screen and (max-width: 1024px) {
  .header {
    height: fit-content; /* Adjust height to fit content */
    padding: 20px; /* Add padding for spacing */
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 250px; /* Set a specific height for tablets */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
  }
}

@media screen and (max-width: 500px) {
  .header {
    height: 150px; /* Set a specific height for mobile */
    margin-top: 150px; /* Keep margin top for spacing */
    padding: 10px; /* Reduce padding for mobile */
  }
}

.p-on-desktop {
  padding: 30px 10px; /* Adjust padding for better spacing */
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.5); /* Keep the shadow */
  margin: 20px; /* Unified margin for better spacing */
  transition: transform 0.3s ease; /* Smooth transition for hover effects */
}

.p-on-desktop:hover {
  transform: translateY(-5px); /* Subtle hover effect */
}

@media screen and (min-width: 1024px) {
  .p-on-desktop {
    margin-top: 80px; /* Extra margin for larger screens */
  }
}
