/* styles.css */

.contact-form {
  margin: 0 auto;
  width: 80%;
  /* max-width: 500px; */
}

.contact-form h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.contact-form textarea {
  height: 10rem;
}

.contact-form button {
  /* display: block; */
  width: 100%;
  background-color: #007bff;
  color: #fff;
  /* padding: 0.5rem; */
  font-size: 1.2rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0069d9;
}
.jdf {
  border: 2px solid red;
}
